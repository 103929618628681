export function fill12Mounth(arr = [], pname) {
    if(!arr?.length)return;
    let data = [...arr];  
    for(let i = data.length ; i < 12; i++) {        
        data.push({
            month: (i+1) + '月',
            value: 0
        }) 
    } 
    return data
}