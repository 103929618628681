import React from "react";
import { Line } from '@ant-design/charts';
export default function LineTwoMonthChart(props) {
    const data = props.transaction?.length?props.transaction:[];
    
      const config = {
        animation: {
            // 配置图表第一次加载时的入场动画
            appear: {
              animation: 'wave-in', // 动画效果
              delay: 500,
              duration: 2000,  // 动画执行时间
            },
        }, 
        padding: [20,80,20,80],
        interactions: [{ type: 'element-active' }],
        state: {
          // 设置 active 激活状态的样式
          active: {
            animate: { duration: 100, easing: 'easeLinear' },
            style: {
              lineWidth: 2,
              stroke: '#fff',
            },
          },
        },
        data, 
        xField: 'month',
        yField: 'value', 
        color: '#06E0D5', 
        point: {
          color: '#FD9442',
          size: 3,
          shape: 'cilcle',
          style: {
             
          }, 
        },  
        tooltip: { 
            title: '商品数',
            showCrosshairs: true,
            enterable: true,   
        }, 
        legend: {
            layout: 'horizontal',
            position: 'top'
        },
        title: {
            text:"123" 
        }, 
        subTickLine: {
            style: {
                count:200,
                fill: '#3784AA',
            } 
        }, 
        xAxis: {
            grid: {
              alignTick: false, 
              line: {
                style: {
                  stroke: '#3784AA', 
                  lineWidth: 0,
                  opacity: 0,
                  lineDash: [], 
                }, 
              }, 
            },  
        },
        yAxis: {
            grid: {
                line: {
                    style: {
                    stroke: '#3784AA', 
                    lineWidth: 1,
                    opacity: 1,
                    lineDash: [2,2], 
                    }, 
                }
            },    
        },
        lineStyle: {
          stroke: '#FD9442',
          lineWidth: 2,
          lineDash: [0,0],
          strokeOpacity: 1,
          shadowColor: 'black',  
        } 
      };
      return <Line {...config} />;
}