import axios from 'axios'    
// import {
//     message
// } from 'antd'
const instance = axios.create({
    // baseURL: 'http://192.168.10.50:8111',
    baseURL: 'https://seller.zjlive.cn',
    // baseURL: 'https://shopadmin.jxhl.cn',
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
    timeout: 5000
})

// 请求拦截器 在发起http请求之前的一些操作
// 1、发送请求之前，加载一些组件
// 2、某些请求需要携带token，如果说没有没有携带，直接跳转到登录页面
instance.interceptors.request.use((config) => {  
    let token = localStorage.getItem("access_token") ? localStorage.getItem("access_token") : false
    if (token) {
        //将token放到请求头发送给服务器,将tokenkey放在请求头中
        config.headers.Authorization = token;     
        //也可以这种写法
        // config.headers['Authorization'] = Token; 
    }    
    return config;
}, err => {
    return err
})

// 响应拦截器
instance.interceptors.response.use((res) => {   
    // if(res.data.state !== 200) { 
    //     message.warning(res.data.msg)
    //     return
    // }
    return res
}, err => {
    if (err && err.response) {
        switch(err.response.status) {
            case 400:
                console.log('请求错误')
                break
            case 401:
                console.log('未认证')
                break
            default:
                console.log('其他信息错误')
        }
    }
})

export default instance