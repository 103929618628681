import React from 'react';
import ReactDOM from 'react-dom/client';  
import App from './App' // 根组件
import { HashRouter} from "react-router-dom"; 
import 'antd/dist/antd.css'; 

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
<HashRouter>
<App/>
</HashRouter>);
