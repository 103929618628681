import React from 'react'; 
import { Column } from '@ant-design/plots';

export default function ColumnChart (props) {
  const data = props.todaySalesChart;

  const config = {
    data,
    padding: [20, 80, 20, 80],
    xField: 'categoryName',
    yField: 'saleNum',
    color: '#2380FC', 
    maxColumnWidth:32,
    label: {
      // 可手动配置 label 数据标签位置
      position: 'middle',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      categoryName: {
        alias: '类别',
      },
      saleNum: {
        alias: '笔',
      },
    },
  };
  return <Column {...config} />;
};
 
