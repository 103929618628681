import React, { useState, useEffect } from 'react';
import request from '../../utils/request'
import ColumnChart from '../columnChart'
import BarChart from '../barChart';
import TitleRow from '../titleRow';
import PieChart from '../pieChart';
import SmoothLineChart from '../smooth';
import SmoothBlueLineChart from '../smoothBlue';
import SmoothBlueLineVisitChart from '../smoothBlueVisit'
import LineTwoMonthChart from '../lineTwoMounthChart';
import { fill12Mounth } from '../../utils/util'
export default function OtherPanel(props) {  
    const [goodsArr, setGoodsArr] = useState([]) // 平台商品数 
    const [storeArr, setStoreArr] = useState([]) // 平台店铺数 
    const [todaySalesChart, setTodaySalesChart] = useState([]) // 平台农特品销量排行 
    const [monthSalesVolume, setMmonthSalesVolume] = useState([]) // 农特品月销量统计 
    const [statisticalTime, setStatisticalTime] = useState([]) // 农产品月度统计时间

    const [officialShopRate, setOfficialShopRate] = useState([]) // 自营占比 
    const [settledShopRate, setSettledShopRate] = useState([]) // 入驻占比 

    const [visitTrend, setVisitTrend] = useState([]) // 平台访问量 
    const [transaction, setTransaction] = useState([]) // 平台月度交易曲线图 

    const [pvuv, setPvuv] = useState({}) // pvuv 
    const [userDataStatistics, setUserDataStatistics] = useState({}) // 平台用户数据统计 
    const [businessWarning, setBusinessWarning] = useState({}) // 经营预警统计 
    const [transactionAnalysis, setTransactionAnalysis] = useState({}) // 平台月度交易分析 
    
    const getMerchantProductTrends = () => {
        request('/v3/cockpit/admin/dashboard/merchantProductTrends').then(res => {  
            if(res?.data.state === 200) { 
                let arr = res.data.data.sort((a,b)=>{
                    return a.monthDate - b.monthDate
                }) 
                let goodsArr = arr.map(item => {
                    return {
                        month: item.monthDate +'月',
                        value: item.newGoodsNum
                    }
                })
                let storeArr = arr.map(item => {
                    return {
                        month: item.monthDate +'月',
                        value: item.newStoreNum
                    }
                })
                
                // console.log('storeArr', storeArr);
                // console.log('fill12Mounth', fill12Mounth(goodsArr));
                setGoodsArr(fill12Mounth(goodsArr));
                setStoreArr(fill12Mounth(storeArr)); 
            } 
        })
    }
    // 平台农特品销量排行 
    const getDaySalesVolume = () => {
        request('/v3/cockpit/admin/dashboard/daySalesVolume').then(res => {   
            if(res?.data.state === 200) { 
                console.log(res.data.data.todaySalesChart);
                setTodaySalesChart(res.data.data.todaySalesChart)
            } 
        })
    }
    // 农特品月销量统计
    const getMonthSalesVolume = () => {
        request('/v3/cockpit/admin/dashboard/monthSalesVolume').then(res => {  
            if(res?.data.state === 200) { 
                console.log(res.data.data.monthSalesChart); 
                setStatisticalTime(res.data.data.statisticalTime)
                setMmonthSalesVolume(res.data.data.monthSalesChart)
                setOfficialShopRate(res.data.data.officialShopRate)
                setSettledShopRate(res.data.data.settledShopRate) 
            } 
        })
    }
    // 平台访问量分析
    const getVisitStatistics = () => {
        request('/v3/cockpit/admin/dashboard/visitStatistics').then(res => {  
            if(res?.data.state === 200) { 
                let visitTrend = res.data.data.visitTrend.map(item => {
                    return {
                        month: item.statsTime + '月',
                        value: item.visitorNum
                    }
                })
                console.log("getVisitStatistics", visitTrend);
                setVisitTrend(fill12Mounth(visitTrend) ) 
            } 
        })
    }
    // 今日pv/uv统计
    const getPvUv = () => {
        request('/v3/cockpit/admin/dashboard/getPvUv').then(res => {   
            if(res?.data.state === 200) {  
                setPvuv(res.data.data)
            } 
        })
    }
    // 平台用户数据统计
    const getUserDataStatistics = () => {
        request('/v3/cockpit/admin/dashboard/userDataStatistics').then(res => {  
            if(res?.data.state === 200) {  
                setUserDataStatistics(res.data.data)
            } 
        })
    }
    // 经营预警
    const getBusinessWarning = () => {
        request('/v3/cockpit/admin/dashboard/businessWarning').then(res => {  
            if(res?.data.state === 200) {  
                setBusinessWarning(res.data.data)
            } 
        })
    }
    // 平台月度交易分析
    const getTransactionAnalysis = () => {
        request('/v3/cockpit/admin/dashboard/transactionAnalysis').then(res => {  
            if(res?.data.state === 200) {  
                let arr = res.data.data.monthTradingVolume
                let monthTradingVolume = arr.map(item => {
                    return {
                        month: item.statsTime,
                        value: item.orderPayNum
                    }
                })
                console.log("平台月度交易分析: ",res.data.data.monthTradingVolume);
                setTransactionAnalysis(res.data.data)
                setTransaction(monthTradingVolume)
            } 
        })
    }

    useEffect(() => {  
        getMerchantProductTrends() 
        getDaySalesVolume() 
        getMonthSalesVolume() 
        getVisitStatistics() 
        getPvUv() 
        getUserDataStatistics() 
        getBusinessWarning() 
        getTransactionAnalysis() 
    },[])

    return (
        <>
        <div className='other'>
            { 
                ((props.currentIndex === 3 && props.direction === "left")  ||(props.currentIndex === 0 && props.direction === "right")  || props.currentIndex === 1 && props.direction === "center") &&
                <div className='columnChart'>
                    <TitleRow title='农特品单日销量排行'/>
                    <ColumnChart todaySalesChart={todaySalesChart}/>
                    <TitleRow title="农特品月度分类排行" rtitle={'统计时间：' + statisticalTime}/>
                    <div className='flex_between'> 
                        <div className='barChart'>
                            <BarChart monthSalesVolume={monthSalesVolume}/>
                        </div>
                        <div className='radioChart'>
                            <PieChart officialShopRate={officialShopRate} settledShopRate= {settledShopRate}/>
                        </div>
                    </div> 
                </div>
            } 
            { 
                ((props.currentIndex === 0 && props.direction === "left")  || (props.currentIndex === 2 && props.direction === "center")|| (props.currentIndex === 4 && props.direction === "right")) &&
                <div className='columnChart'>
                    <TitleRow title="平台商品数"/>
                    <SmoothLineChart goodsArr={goodsArr}/>
                    <TitleRow title="平台店铺数"/> 
                    <SmoothBlueLineChart storeArr={storeArr}/>
                </div>
            } 
            { 
                ((props.currentIndex === 1 && props.direction === "right") || (props.currentIndex === 3 && props.direction === "center") || (props.currentIndex === 5 && props.direction === "left")) &&
                <>
                    <div className='lineTwoMonthChart'>
                        <LineTwoMonthChart transaction={transaction}/> 
                    </div>
                    <div className='flex_evenly'>
                        <div className='num'>
                            <div className='box'>￥{transactionAnalysis.aveConsumption}</div>
                            <div className='bottom'>平均客单价</div>
                        </div>
                        <div className='num'>
                            <div className='box'>{transactionAnalysis.repurchaseRate}%</div>
                            <div className='bottom'>复购率</div>
                        </div>
                    </div>
                </> 
            }
            { 
                ((props.currentIndex === 2 && props.direction === "left") || (props.currentIndex === 4 && props.direction === "center") || (props.currentIndex === 6 && props.direction === "right")) &&
                <>
                    <TitleRow title='平台访问量趋势图'/>
                    <div className='lineTwoMonthChart'>
                        <SmoothBlueLineVisitChart visitTrend={visitTrend}/> 
                    </div>
                    <div className='today'>今日平台访问量</div>
                    <div className='flex_evenly'>
                        <div className='num'>
                            <div className='up'>{pvuv.pv}</div>
                            <div className='down'>访问次数（PV）</div>
                        </div>
                        <div className='num'>
                            <div className='up'>{pvuv.uv}</div>
                            <div className='down'>访问人数（UV）</div>
                        </div>
                    </div>
                </> 
            }
            { 
                ((props.currentIndex === 6 && props.direction === "left") ||(props.currentIndex === 5 && props.direction === "center") || (props.currentIndex === 3 && props.direction === "right")) &&
                <>
                    <div className='earth'>
                         <div className='earthTxt'>县域合伙人数</div>
                         <div className='earthNum'>{userDataStatistics.partnerNum}<span className='ren'>人</span></div>
                    </div>
                    <TitleRow title='用户数据'/>
                    <div className='flex_between'>
                        <div className='barChart'>
                            <div className='userItem'>
                                <div className='icon'></div>
                                <div className='textNum'>家乡推荐官人数</div>
                                <div className='humanNum'>{userDataStatistics.referralOfficerNum}</div>
                            </div>
                            <div className='userItem'>
                                <div className='icon'></div>
                                <div className='textNum'>助农大使人数</div>
                                <div className='humanNum'>{userDataStatistics.ambassadorNum}</div>
                            </div>
                        </div>
                        <div className='userData'>
                             <div className="circle">{userDataStatistics.proportion}%</div>
                             <div className="lengend">
                                <div className='homeHelper'>家乡推荐官</div>
                                <div className='farmerHelper'>助农大使</div>
                             </div>
                        </div>
                    </div>
                </> 
            }
            { 
              ((props.currentIndex === 4 && props.direction === "left") || (props.currentIndex === 6 && props.direction === "center") || (props.currentIndex === 5 && props.direction === "right")) &&
                <>
                    <TitleRow title='佣金'/> 
                    <div className='between'>
                        <div className='green'> 
                            <div className='greenNum'>{businessWarning.distributeCommission}</div>
                            <div className='itemboxTop'>已发放（元）</div>
                        </div>
                        <div className='blue'>
                            <div className='blueNum'>{businessWarning.commissionTotal}</div>
                            <div className='itemboxTop'>平台佣金总额（元）</div>
                        </div>
                        <div className='red'>
                            <div className='redNum'>{businessWarning.noDistributeCommission}</div>
                            <div className='itemboxTop'>未发放（元）</div>
                        </div>
                    </div> 
                    <TitleRow title='贡献值'/>
                    <div className='between'>
                        <div className='itembox'>{businessWarning.contributionTotal} <div className='textItem'>平台贡献值总数</div> </div>
                        <div className='redcenter'>{businessWarning.noRedeemedContributions}<div className='textItem'>未兑换贡献值</div></div>
                        <div className='itembox'>{businessWarning.convertibleGoodsNum}<div className='textItem'>可兑换商品数</div></div>
                    </div>
                </> 
            }
        </div>
        </>
        
    )
}