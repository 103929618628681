import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import request from '../../utils/request'
import LineChart from '../lineChart' 
import LineHistory from '../lineHistoryChart'
import {
    message
} from 'antd'

 
export default function HomePanel() {
    const [totalCount, setTotalCount] = useState(0)
    const [curTab, setCurTab] = useState(0)
    const [curSwitch, setSwitch] = useState(false)
 
    const [time, setTime] = useState('')
    const [day, setDay] = useState('')
    const [orderSuccessRate, setOrderSuccessRate] = useState('')
    const [yesterdaySYoY, setYesterdaySYoY] = useState('')
    const [lastWeekSYoY, setLastWeekSYoY] = useState('')
    const [lastMonthYoY, setLastMonthYoY] = useState('')

    const navigate = useNavigate()
    // 实时交易趋势
    const [realTimeTradingTrends, setRealTimeTradingTrends] = useState({})
    // 历史交易趋势
    const [historyTradingTrends, setHistoryTradingTrends] = useState({})


    const dataIndicators = () => {
        request('/v3/cockpit/admin/dashboard/dataIndicators').then(res => {  
            if(res?.data.state === 200) {
                let { orderSuccessRate, yesterdaySYoY, lastWeekSYoY, lastMonthYoY } = res.data.data    
                setOrderSuccessRate(orderSuccessRate)
                setYesterdaySYoY(yesterdaySYoY)
                setLastWeekSYoY(lastWeekSYoY)
                setLastMonthYoY(lastMonthYoY)
            } 
            if(res?.data.state === 266) { 
                navigate('/login')  
                return
            }
        })
    }
    const getRealTimeTradingTrends = () => {
        request('/v3/cockpit/admin/dashboard/realTimeTradingTrends').then(res => {  
            if(res?.data.state === 200) {
                let data = res.data.data   
                console.log('实时交易趋势 :>> ', data.chart);
                setRealTimeTradingTrends(data.chart) 
            } 
        })
    } 
    const getHistoryTradingTrends = () => {
        request('/v3/cockpit/admin/dashboard/historyTradingTrends',{
            params: {
                type: curSwitch? '1': '2'
            }
        }).then(res => {  
            if(res?.data.state === 200) {
                let data = res?.data.data   
                console.log('历史交易趋势 :>> ', data);
                setHistoryTradingTrends(data) 
            } 
        })
    } 
    useEffect(()=>{ 
        dataIndicators()
        getRealTimeTradingTrends()
        getHistoryTradingTrends()
        let timer1 = setInterval(()=> {
            dataIndicators()
            getRealTimeTradingTrends()
            getHistoryTradingTrends()
        }, 3600000)
        let timer2 = setInterval(()=> {
        request('/v3/cockpit/admin/dashboard/realTimeTradingVolume').then(res => { 
            if(res?.data.state === 200) {
                let {currentTime, dayName, tradingVolume} = res.data.data   
                setTime(currentTime)
                setDay(dayName)
                setTotalCount(tradingVolume)
            } else {
                message.warning(res?.data.msg)
                navigate('/login')  
                return
            } 
          })
      },1000) 
      return ()=>{
        clearInterval(timer1)
        clearInterval(timer2)
      }
    },[curSwitch])
    return (
        <div className="center">
            <div className="top time">{time}</div>
            <div className="top day">{day}</div> 
            <div className="top totalCount"><span className='qian'>￥</span>{totalCount}</div>
            <div className="middle">
                <div className="square">
                    <div className="fourIcon orderSuccess"></div>
                    <div className="rightSquare">
                        <div className="digtal">{orderSuccessRate}%</div>
                        <div className="bottomText">
                            订单成功率
                            <span className={orderSuccessRate > 0 ? "arrow upArrow": "arrow downArrow"}></span>
                        </div>
                    </div>
                </div>
                <div className="square">
                    <div className="fourIcon lastDay"></div>
                    <div className="rightSquare">
                        <div className="digtal">{yesterdaySYoY}%</div>
                        <div className="bottomText">
                            昨日同比
                            <span className={yesterdaySYoY > 0 ? "arrow upArrow": "arrow downArrow"}></span>
                        </div>
                    </div>
                </div>
                <div className="square">
                    <div className="fourIcon lastWeek"></div>
                    <div className="rightSquare">
                        <div className="digtal">{lastWeekSYoY}%</div>
                        <div className="bottomText">
                            上周同比
                            <span className={lastWeekSYoY > 0 ? "arrow upArrow": "arrow downArrow"}></span>
                        </div>
                    </div>
                </div>
                <div className="square">
                    <div className="fourIcon lastMonth"></div>
                    <div className="rightSquare">
                        <div className="digtal">{lastMonthYoY}%</div>
                        <div className="bottomText">
                            上月同比
                            <span className={lastMonthYoY > 0 ? "arrow upArrow": "arrow downArrow"}></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className={curTab === 0? 'tabs':'tabs_select'}>
                    <div className="t1" onClick={()=>setCurTab(0)}>实时交易趋势</div>
                    <div className="t2" onClick={()=>setCurTab(1)}>历史交易趋势</div>
                    {curTab === 1 && <div className={curSwitch? 'switch s1':'switch s2'} onClick={()=>setSwitch(!curSwitch)}></div>} 
                </div>
                <div className="chartLine">
                    {curTab === 0 && <LineChart realTimeTradingTrends={realTimeTradingTrends} />} 
                    {curTab === 1 && <LineHistory historyTradingTrends={historyTradingTrends}/>}  
                </div>
            </div>
        </div>
    )
}