import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import css from "./login.module.css" 
import request from '../../utils/request'
 
export default function Login() {
  // kfUser 111111
  let [username , setUsername] = useState('')
  let [password , setPassword] = useState('') 
  let [isSave , setSave] = useState(false) 
  const navigate = useNavigate()

  const handleScreenAuto = () => {
    
    const designDraftWidth = 1920; // 设计稿的宽度
    const designDraftHeight = 1080; // 设计感的高度
    // 根据屏幕的变化适配的比例
    const scale = document.documentElement.clientWidth / document.documentElement.clientHeight < designDraftWidth / designDraftHeight ?
    (document.documentElement.clientWidth/designDraftWidth) : (document.documentElement.clientHeight/designDraftHeight)
    document.querySelector('#login').getElementsByClassName.transform = `scale(${scale}) translate(-50%)`
    console.log(scale);
  }

  useEffect(()=>{ 
    if(localStorage.getItem('isSave')) { 
      setUsername(localStorage.getItem('username'))
      setPassword(localStorage.getItem('password'))
      setSave(localStorage.getItem('isSave'))
    }
    handleScreenAuto();
    window.onresize = () => handleScreenAuto();
    return  () => window.onresize = null;
  },[])

  const submit = ()=> {   
    request({
      method: "post",
      url: '/v3/cockpit/admin/dashboard/dashboardLogin', 
      data: {
        username,
        password
      }
    }).then(res => {    
      if( res?.data.state === 200 ) { 
        let { access_token, adminId, adminName, isSuper } = res?.data.data
        localStorage.setItem('access_token', access_token)
        localStorage.setItem('adminId', adminId)
        localStorage.setItem('adminName', adminName)
        localStorage.setItem('isSuper', isSuper)
        localStorage.setItem('isSave', isSave)
        localStorage.setItem('username', username)
        localStorage.setItem('password', password)
        navigate('/dashboard')
      } 
    }).catch(err=> {
      console.log(err);
    }) 
  }
 
  return (
    <div className={css.container} id='login'>  
        <div className={css.login}>
            <div className={css.username + ' '+ css.ipt}>
              <input className={css.usr} placeholder="请输入用户名" type="text" value={username} onChange={(e)=>setUsername(e.target.value)}/>
            </div> 
            <div className={css.pwd + ' '+ css.ipt}>
              <input className={css.password} placeholder="请输入密码" type="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
            </div> 
            <div className={css.remember}>
                <div className={isSave ? css.icon: css.un} onClick={()=>setSave(!isSave)}></div>
                <div className={css.rememberText}>记住密码</div>
            </div> 
            <a href="#" className={css.submit} onClick={submit}></a>
        </div>
    </div>
  )
}
