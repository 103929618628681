import React from 'react'; 
import { Pie } from '@ant-design/plots';

export default function PieChart(props) {
  const data = [
    {
      type: '入住店铺',
      value: props.settledShopRate,
    },
    {
      type: '平台自营',
      value: props.officialShopRate,
    }, 
  ];
  const config = {
    appendPadding: 10,
    width: 250,
    height: 250,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}%',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#3EB7F8'
        },
        content: '占比',
      },
    }, 
    legend: {
        layout: 'vertical',
        position: 'bottom'
    }
  };
  return <Pie {...config} />;
};
 