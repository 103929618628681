import React from 'react'
import { Routes, Route} from 'react-router-dom'
import DashBoard from './pages/dashboard'
import Login from './pages/login'

export default function App() { 
    return (<Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashBoard" element={<DashBoard />} />
                <Route path="/login" element={<Login />} />
            </Routes>)
} 
