import React, { useEffect, useState } from 'react'; 
import { useNavigate } from 'react-router-dom';
import HomePanel from '../../component/homePanel';
import OtherPanel from '../../component/otherPanel';  
import './index.css';  
import request from '../../utils/request'
import autofit from 'autofit.js'

export default function Dashboard() {
  let [ currentIndex, setCurrentIndex ] = useState(0)
  let [ showExitBox, setShowExitBox ] = useState(false)
  const navigate = useNavigate()
  const changeCurrentIndexAdd = () => {
    currentIndex++;
    if( currentIndex === 7) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(currentIndex)
    }  
  }
  
  const changeCurrentIndexMis = () => {
    currentIndex--;
    if( currentIndex < 0) {
      setCurrentIndex(6) 
    } else {
      setCurrentIndex(currentIndex)
    } 
  }
 
  const confirm = () => {
    request({
      method: "post",
      url: '/v3/adminLogin/oauth/logout',
      data: {
         refresh_token: localStorage.getItem("access_token")
      }
    }).then(res => {   
      if( res.data.state === 200 ) { 
        localStorage.clear();
        navigate('/login')
      } 
    })
  }

  // 数据大屏自适应
  const handleScreenAuto = () => {
    
    const designDraftWidth = 1920; // 设计稿的宽度
    const designDraftHeight = 1080; // 设计感的高度
    // 根据屏幕的变化适配的比例

    autofit.init({
      designHeight: designDraftHeight,
      designWidth: designDraftWidth,
      renderDom:"#screen",
      resize: true
  })

    // const scale = document.documentElement.clientWidth / document.documentElement.clientHeight < designDraftWidth / designDraftHeight ?
    // (document.documentElement.clientWidth/designDraftWidth) : (document.documentElement.clientHeight/designDraftHeight)
    // document.querySelector('#screen').getElementsByClassName.transform = `scale(${scale}) translate(-50%)`
    // console.log(scale);
  }

  useEffect(()=>{
    handleScreenAuto();
    window.onresize = () => handleScreenAuto();
    return  () => window.onresize = null;
  },[])

  return (
    <div className="container" id='screen'>
      {
        showExitBox && <div className='mask'>
          <div className='exitBox'>
            <div className='exitBtn cancel' onClick={()=>setShowExitBox(false)}></div>
            <div className='exitBtn confirm' onClick={()=>confirm()}></div>
          </div>
        </div>
      } 
      <div className='exit' onClick={()=>setShowExitBox(true)}>
        <div className='exitIcon'></div>
        <div>退出</div>
      </div>
			<div className="title">
      {
        currentIndex === 0 && '当前实时交易量'
      }
      {
        currentIndex === 1 && '平台农特品销量排行'
      }
      {
        currentIndex === 2 && '平台商户商品趋势图'
      } 
      {
        currentIndex === 3 && '平台月度交易分析'
      }
      {
        currentIndex === 4 && '平台访问量分析'
      }
      {
        currentIndex === 5 && '平台用户数据'
      }
      {
        currentIndex === 6 && '经营预警'
      } 
      </div>
			<div className="leftTitle subText"> 
        {
        currentIndex === 0 && '平台商户趋势图'
        }
        {
          currentIndex === 1 && '当前实时交易量'
        }
        {
          currentIndex === 2 && '平台访问量趋势图'
        }
        {
          currentIndex === 3 && '农特品排行'
        }
        {
          currentIndex === 4 && '经营预警'
        }
        {
          currentIndex === 5 && '平台月度交易分析'
        }
        {
          currentIndex === 6 && '平台用户数据'
        }

      </div>
      <div className='leftContent'>
 
      {
        currentIndex === 1 && <HomePanel />
      }
      {
        currentIndex !== 1 && <OtherPanel currentIndex={currentIndex} direction="left" />
      }
      </div>
			<div className="rightTitle subText"> 
        {
          currentIndex === 0 && '农特品分类排行'
        }
        {
          currentIndex === 1 && '平台月度交易分析'
        }
        {
          currentIndex === 2 && '当前实时交易量'
        }  
        {
          currentIndex === 3 && '平台用户数据'
        }
        {
          currentIndex === 4 && '平台商户趋势图'
        }
        {
          currentIndex === 5 && '经营预警'
        }
        {
          currentIndex === 6 && '平台访问量'
        }
        
      </div>
      <div className='rightContent'>
      {
        currentIndex !== 2 && <OtherPanel currentIndex={currentIndex} direction="right" />
      } 
       
      {
        currentIndex === 2 && <HomePanel />
      }
      </div>
      {
        (currentIndex === 0) && <HomePanel />
      }
      {
        currentIndex !== 0  && <OtherPanel currentIndex={currentIndex} direction="center"/>
      } 
			<div className="swiper">
				<div className="nav left" onClick={changeCurrentIndexMis}></div>
        {
          currentIndex === 0 && <>
          <div className="item ptfwl" onClick={()=>setCurrentIndex(4)}></div>
          <div className="item ptshspqs" onClick={()=>setCurrentIndex(2)}></div>
				  <div className="item ntpxlph" onClick={()=>setCurrentIndex(1)}></div>
				  <div className="item ydjyfx" onClick={()=>setCurrentIndex(3)}></div>
          </>
        } 
        {
          currentIndex === 1 && <>
          <div className="item ptshspqs" onClick={()=>setCurrentIndex(2)}></div> 
          <div className="item dqssjyl" onClick={()=>setCurrentIndex(0)}></div>
          <div className="item ptydjyfx" onClick={()=>setCurrentIndex(3)}></div>
          <div className="item ptyhsj" onClick={()=>setCurrentIndex(5)}></div>
          </>
        }
        {
          currentIndex === 2 && <>
          <div className="item jyyj" onClick={()=>setCurrentIndex(6)}></div> 
          <div className="item ptfwl" onClick={()=>setCurrentIndex(4)}></div>
          <div className="item dqssjyl" onClick={()=>setCurrentIndex(0)}></div>
          <div className="item ntpxlph" onClick={()=>setCurrentIndex(1)}></div>
          </>
        }
        {
          currentIndex === 3 && <>
          <div className="item dqssjyl" onClick={()=>setCurrentIndex(0)}></div>
          <div className="item ntpxlph" onClick={()=>setCurrentIndex(1)}></div>
          <div className="item ptyhsj" onClick={()=>setCurrentIndex(5)}></div>
          <div className="item jyyj" onClick={()=>setCurrentIndex(6)}></div> 
          </>
        }
        {
          currentIndex === 4 && <>
          <div className="item ptyhsj" onClick={()=>setCurrentIndex(5)}></div>
          <div className="item jyyj" onClick={()=>setCurrentIndex(6)}></div> 
          <div className="item ptshspqs" onClick={()=>setCurrentIndex(2)}></div>
          <div className="item dqssjyl" onClick={()=>setCurrentIndex(0)}></div>  
          </>
        }
        {
          currentIndex === 5  && <>
           <div className="item ntpxlph" onClick={()=>setCurrentIndex(1)}></div>
           <div className="item ptydjyfx" onClick={()=>setCurrentIndex(3)}></div>
           <div className="item jyyj" onClick={()=>setCurrentIndex(6)}></div>  
           <div className="item ptfwl" onClick={()=>setCurrentIndex(4)}></div>  
          </>
        }
        {
          currentIndex === 6  && <>
          <div className="item ptydjyfx" onClick={()=>setCurrentIndex(3)}></div>
          <div className="item ptyhsj" onClick={()=>setCurrentIndex(5)}></div>
          <div className="item ptfwl" onClick={()=>setCurrentIndex(4)}></div> 
          <div className="item ptshspqs" onClick={()=>setCurrentIndex(4)}></div>  
          </>
        }  
				<div className="nav right" onClick={changeCurrentIndexAdd}></div>
			</div>
		</div>
  )
}
