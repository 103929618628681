import React from "react";
import { Line } from '@ant-design/charts';
export default function LineChart(props) { 
    let data = props.historyTradingTrends?.length ?  props.historyTradingTrends :[]

      const config = {
        animation: {
            // 配置图表第一次加载时的入场动画
            appear: {
              animation: 'wave-in', // 动画效果
              delay: 1500,
              duration: 2000,  // 动画执行时间
            },
        },
        interactions: [{ type: 'element-active' }],
        state: {
          // 设置 active 激活状态的样式
          active: {
            animate: { duration: 100, easing: 'easeLinear' },
            style: {
              lineWidth: 2,
              stroke: '#fff',
            },
          },
        },
        data, 
        xField: 'statsTime',
        yField: 'orderSubmitNum', 
        color: '#02AAFB', 
        point: {
          size: 5,
          shape: 'cilcle',
          style: {
             
          }, 
        },  
        tooltip: { 
            title: 'orderSubmitNum', 
            fields: [], 
            enterable: true, 
        }, 
        legend: {
            layout: 'horizontal',
            position: 'right'
        },
        label: {
            style: {
                fill: '#FF9B03',
                opacity: 1,   
                spacing: 10, 
                fontSize: 16
            }, 
            offsetY: -3,
            labelLine:true
        }, 
        subTickLine: {
            style: {
                count:200,
                fill: '#3784AA',
            } 
        }, 
        xAxis: {
            grid: {
              alignTick: false, 
              line: {
                style: {
                  stroke: '#3784AA', 
                  lineWidth: 1,
                  opacity: 1,
                  lineDash: [], 
                }, 
              }, 
            },  
        },
        yAxis: {
            grid: {
                line: {
                    style: {
                    stroke: '#3784AA', 
                    lineWidth: 1,
                    opacity: 1,
                    lineDash: [], 
                    }, 
                }
            },    
        },
        lineStyle: {
          stroke: '#80C0FE',
          lineWidth: 2,
          lineDash: [0,0],
          strokeOpacity: 1,
          shadowColor: 'black',  
        } 
      };
      return <Line {...config} />;
}