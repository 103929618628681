import React from 'react'; 
import { Bar } from '@ant-design/plots';

export default function  BarChart (props) {
  const data = props.monthSalesVolume;
  const config = {
    data,
    padding: [10, 10, 20, 100],
    height: 250,
    width: 150,
    xField: 'orderSubmitNum',
    yField: 'categoryName',
    seriesField: 'categoryName', 
    legend: false,
  };
  return <Bar {...config} />;
};
 